import React, { useState } from "react";
import { useAuthState } from "../../Context";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";

import ROOT_URL from "../../Config/rootUrl";
import Layout from "../../Components/Layout/Layout";

const ViewFinderComponent = () => {
  return (
    <>
      <svg
        width="50px"
        viewBox="0 0 100 100"
        style={{
          top: 0,
          left: 0,
          zIndex: 1,
          boxSizing: "border-box",
          border: "50px solid rgba(0, 0, 0, 0.3)",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <path
          fill="none"
          d="M13,0 L0,0 L0,13"
          stroke="rgba(255, 255, 255, 0.5)"
          strokeWidth="5"
        ></path>
        <path
          fill="none"
          d="M0,87 L0,100 L13,100"
          stroke="rgba(255, 255, 255, 0.5)"
          strokeWidth="5"
        ></path>
        <path
          fill="none"
          d="M87,100 L100,100 L100,87"
          stroke="rgba(255, 255, 255, 0.5)"
          strokeWidth="5"
        ></path>
        <path
          fill="none"
          d="M100,13 L100,0 87,0"
          stroke="rgba(255, 255, 255, 0.5)"
          strokeWidth="5"
        ></path>
      </svg>
    </>
  );
};

const QrScanner = () => {
  const userDetails = useAuthState();
  const [scanning, setScanning] = useState(true);

  const navigate = useNavigate();

  const sendRequest = async (qrData) => {
    const token = userDetails?.token;
    if (!token) return;

    const payload = { exercubeIdentifier: qrData };

    const response = await fetch(`${ROOT_URL}/api/v1/auth/qr_app`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (!response.ok) {
      console.log(response);
      console.log(data.error.message);
      return;
    }

    setScanning(false);
    setTimeout(() => {
      navigate("/"); // navigate to Home on success
    }, 2000);
    console.log(data);
  };

  return (
    <Layout>
      {!scanning && (
        <div
          style={{
            margin: "auto",
            width: "400px",
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#00ff00",
            minHeight: "400px",
          }}
        >
          <BsFillCheckCircleFill style={{ fontSize: 64, marginBottom: 16 }} />
          <p>Returning to exerhub...</p>
        </div>
      )}

      {!!scanning && (
        <div
          style={{
            margin: "auto",
            width: "400px",
            maxWidth: "100%",
          }}
        >
          <QrReader
            constraints={{ facingMode: "environment" }}
            ViewFinder={ViewFinderComponent}
            onResult={(result, error) => {
              if (!!result) {
                sendRequest(result?.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />

          <div style={{ marginTop: 16 }}>
            <p>Scan the login QR code on the exercube terminal</p>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default QrScanner;
