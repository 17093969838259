import React, { useRef } from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import SpheryAppDefaultImageMale from "../../Assets/SpheryAppDefaultImageMale.jpg";
import Challenge from "../../Assets/Challenge.js";
import Community from "../../Assets/Community.js";
import Dumbbell from "../../Assets/Dumbbell.js";
import Leaderboard from "../../Assets/Leaderboard.js";
import Workout from "../../Assets/Workout";
import Progress from "../../Assets/Progress.js";
import Row from "../../Components/Row/Row";
import DurationStat from "../../Components/WorkoutStat/DurationStat";
import DualflowScoreStat from "../../Components/WorkoutStat/DualflowScoreStat";
import ScoreStat from "../../Components/WorkoutStat/ScoreStat";
import BodyScoreStat from "../../Components/WorkoutStat/BodyScoreStat";
import BrainScoreStat from "../../Components/WorkoutStat/BrainScoreStat";
import MaxComboStat from "../../Components/WorkoutStat/MaxComboStat";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import NewsList from "../../Components/NewsList/NewsList";
import Styles from "./UserHub.module.scss";
import ROOT_URL from "../../Config/rootUrl";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import Settings from "../../Assets/Settings.js";
import { Trans, useTranslation } from "react-i18next";
import GlobalProgress from "../../Components/GlobalProgress/GlobalProgress";

const UserHub = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const newsListRef = useRef(null);
  const scrollToBottom = () => {
    elementScrollIntoView(newsListRef.current, { behavior: "smooth" });
  };

  const { data } = useQuery(["userHub"], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/userhub/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();
    if (data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    // console.log(responseJson);
    return responseJson;
  });

  const unreadMessageData = useQuery(["getUnreadMessages"], async () => {
    const response = await fetch(
      `${ROOT_URL}/api/v1/message/find_all_unread_messages/`,
      {
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
        },
      }
    );

    const responseJson = await response.json();
    if (responseJson.data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });

  const markMessageAsReadMutation = useMutation(
    async (messageId) =>
      await fetch(`${ROOT_URL}/api/v1/message/mark_one_message_as_read`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messageId }),
      }),
    {
      // After success or failure, refetch the query
      onSettled: () => {
        queryClient.invalidateQueries(["getUnreadMessages"]);
      },
    }
  );

  const globalProgress = useQuery(["getGlobalProgress"], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/global-progress/read/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        progress: "global-progress",
      }),
    });

    const responseJson = await response.json();
    if (data?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  }); 

  if (!data) return <LoadingSpinner />;

  return (
    <Layout>
      <div className={Styles.pageHeader}>
        <h1>{data?.username || userDetails?.user?.username}</h1>

        {!!globalProgress?.data && !globalProgress?.data?.error && (
          <GlobalProgress data={globalProgress.data} />
        )}
      </div>

      <div className={Styles.imageContainer}>
        <svg viewBox="0 0 100 15" className={Styles.arrowTop}>
          <polygon points="0,0 0,15 100,0" />
        </svg>

        {data?.workout[0] && (
          <div className={Styles.workoutOverlay}>
            <svg viewBox="0 0 100 5000" className={Styles.arrowBackground}>
              <polygon points="0,500 0,15 100,0 100,500" />
            </svg>

            <h2>{t("userHub.bestWorkoutHeadline")}</h2>
            <h3 className="h1">{data?.workout[0]?.workoutPreset?.name}</h3>
            <Row>
              <DualflowScoreStat value={data?.workout[0]?.dualflowScore} />
              <BodyScoreStat value={data?.workout[0]?.bodyScore} />
              <BrainScoreStat value={data?.workout[0]?.brainScore} />
            </Row>

            <Row>
              <ScoreStat value={data?.workout[0]?.score} />
              <MaxComboStat value={data?.workout[0]?.maxCombo} />
              <DurationStat value={data?.workout[0]?.measuredDuration} />
            </Row>
          </div>
        )}

        <svg viewBox="0 0 100 15" className={Styles.arrowBottom}>
          <polygon points="0,15 100,0 100,15" />
        </svg>

        <picture>
          {data?.profileImage ? (
            <img
              srcSet={`${ROOT_URL}/public/images/${data.profileImage}-320x320.jpg 320w, ${ROOT_URL}/public/images/${data.profileImage}-640x640.jpg 640w, ${ROOT_URL}/public/images/${data.profileImage}-960x960.jpg 960w, ${ROOT_URL}/public/images/${data.profileImage}-1200x1200.jpg 1200w`}
              sizes="(max-width: 1199px) 100vw, 1168px"
              src={`${ROOT_URL}/public/images/${data.profileImage}-640x640.jpg`}
              alt=""
            />
          ) : (
            <img src={SpheryAppDefaultImageMale} alt="" />
          )}
        </picture>
      </div>

      {!data?.workout[0] && (
        <>
          <h3 className="h1">{t("common.noWorkouts.headline")}</h3>

          <h2>{t("common.noWorkouts.subHeadline")}</h2>
          <p>{t("common.noWorkouts.paragraph1")}</p>

          <p>{t("common.noWorkouts.paragraph2")}</p>
          <p className={Styles.small}>{t("common.noWorkouts.smallText")}</p>

          <p>
            <Trans i18nKey="common.noWorkouts.paragraph3">
              <Link to="/workouts">My Workouts</Link>
            </Trans>
          </p>
        </>
      )}

      <div className={Styles.menu}>
        <div className={Styles.buttonGroup}>
          <Link className={Styles.button} to="/workouts">
            <Dumbbell /> {t("common.menu.workouts")}
          </Link>

          <Link className={Styles.button} to="/progress">
            <Progress /> {t("common.menu.progress")}
          </Link>

          <Link className={Styles.button} to="/leaderboards">
            <Leaderboard /> {t("common.menu.leaderboards")}
          </Link>

          <Link className={Styles.button} to="/challenges">
            <Challenge /> {t("common.menu.challenges")}
          </Link>

          <Link className={Styles.button} to="/settings">
            <Settings /> {t("common.menu.settings")}
          </Link>
        </div>
      </div>

      {!!unreadMessageData?.data?.length && (
        <div ref={newsListRef}>
          <NewsList
            newsHeadline={t("userHub.newsHeadline")}
            messages={unreadMessageData.data}
            markMessageAsReadMutation={markMessageAsReadMutation}
          />
          <button
            className={Styles.fixedButton}
            onClick={() => scrollToBottom()}
          >
            <span>
              <b>
                {
                  unreadMessageData.data.filter(
                    (message) => message.type === "workout"
                  ).length
                }
                &times;{" "}
              </b>
              <Workout />
            </span>
            <span>
              <span>
                <b>
                  {
                    unreadMessageData.data.filter(
                      (message) => message.type === "challenge"
                    ).length
                  }
                  &times;{" "}
                </b>
              </span>
              <Challenge />
            </span>
            <span>
              <span>
                <b>
                  {
                    unreadMessageData.data.filter(
                      (message) => message.type === "community"
                    ).length
                  }
                  &times;{" "}
                </b>
              </span>
              <Community />
            </span>
          </button>
        </div>
      )}
    </Layout>
  );
};

export default UserHub;
