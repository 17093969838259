import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import ROOT_URL from "../../Config/rootUrl";
import Popup from "./Popup";

const Overlay = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(
    ["getOverlay"],
    async () => {
      const response = await fetch(`${ROOT_URL}/api/v1/overlay?limit=1`, {
        headers: {
          Authorization: `Bearer ${userDetails.token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseJson = await response.json();

      if (responseJson?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      enabled: !!userDetails?.token,
      refetchInterval: 900000, // 15 minutes in milliseconds
      refetchIntervalInBackground: true,
    }
  );

  const mutation = useMutation(
    async (overlayId) => {
      await fetch(`${ROOT_URL}/api/v1/overlay`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${userDetails.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ overlayId }),
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getOverlay"]);
      },
    }
  );

  if (isLoading || isError || !data?.entries || data.entries.length === 0)
    return null;

  return (
    <Popup
      data={data.entries[0]}
      onClick={() => mutation.mutateAsync(data.entries[0].id)}
    />
  );
};

export default Overlay;
